type TComparableType = { cacheKey?: string; updatedAt: Date | number };

export const compareEntities = <T extends TComparableType>(a: T, b: T): boolean => {
  if (!a || !b) {
    return false;
  }

  if ('cacheKey' in a) {
    return a.cacheKey === b.cacheKey;
  }

  return a.updatedAt === b.updatedAt;
};

export const compareCollection: <T extends TComparableType[]>(prev: T, next: NoInfer<T>) => boolean = (prev, next) => {
  if (prev?.length !== next?.length) {
    return false;
  }

  return prev.every((item, i) => item.cacheKey === next[i]?.cacheKey);
};
